import { useFunction } from 'modules/firebase';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ContactForm } from '..';

export const useContactHook = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const sendContactEmailFn = useFunction('sendContactEmail');

  const sendEmail = async (
    data: ContactForm,
    functionUrl: string,
    auth: string,
  ) => {
    setLoading(true);

    if (sendContactEmailFn)
      sendContactEmailFn({ contactInfo: data, functionUrl, auth })
        .then((resp) => {
          if (resp) {
            setLoading(false);
            toast.success('Your message was sent successfully');
          } else {
            setLoading(false);
            toast.error('Unable to send message. Try again.');
          }
        })
        .catch(() => {
          setLoading(false);
          toast.error('Unable to send message. Try again.');
        });
    else setLoading(false);
  };

  return {
    sendEmail,
    loading,
  };
};
