import firebase from 'firebase/app';
import { useEffect, useMemo, useState } from 'react';

export const useFunction = (functionName: FirebaseFunctions) => {
  const [functionsRef, setFunctionsRef] =
    useState<firebase.functions.Functions>();

  useEffect(() => {
    setFunctionsRef(
      firebase
        .app()
        .functions(
          process.env.GATSBY_FIREBASE_ENV === 'local'
            ? undefined
            : 'us-central1',
        ),
    );
  }, []);

  const callable = useMemo(
    () => functionsRef?.httpsCallable(functionName),
    [functionName, functionsRef],
  );

  return callable;
};
