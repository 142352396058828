import { Button, Container, ValidatedInput } from 'components';
import React from 'react';
import { contactStyles } from 'style';
import { FormProvider, useForm } from 'react-hook-form';
import { SEO } from 'components/seo';
import { ToastContainer } from 'react-toastify';
import { EmailRegExp } from 'consts';
import { useWindowSize } from 'utils';
import { ContactForm, useContactHook } from 'modules/contact';

const Contact: React.FC = () => {
  const [width] = useWindowSize();
  const methods = useForm<ContactForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const { loading, sendEmail } = useContactHook();

  async function onSubmit(data: ContactForm) {
    const functionUrl = process.env.GATSBY_EMAIL_URL + 'email/2/send';
    const auth = 'App ' + process.env.GATSBY_EMAIL_AUTH;

    sendEmail(data, functionUrl, auth);
  }

  return (
    <>
      <SEO
        title="Contact"
        description="Looking to create prosthetic hands or you need one?"
      />
      <Container>
        <div css={contactStyles.root}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div css={contactStyles.inputWrapper}>
                <h2 css={contactStyles.pageTitle}>
                  Looking to create prosthetic hands or you need one?
                </h2>
                <div css={contactStyles.inputs}>
                  <ValidatedInput
                    name="name"
                    label="Full name"
                    validationObject={{
                      required: 'Required field',
                    }}
                  />
                  <ValidatedInput
                    name="email"
                    label="Email address"
                    validationObject={{
                      required: 'Required field',
                      pattern: {
                        value: EmailRegExp,
                        message: 'Please enter a valid email address.',
                      },
                    }}
                  />
                  <ValidatedInput
                    name="subject"
                    label="Subject"
                    validationObject={{
                      required: 'Required field',
                    }}
                  />

                  <div css={contactStyles.textAreaWrapper}>
                    <ValidatedInput
                      name="message"
                      textarea
                      label="Message"
                      validationObject={{
                        required: 'Required field',
                      }}
                    />
                  </div>
                  <div css={contactStyles.buttonWrapper}>
                    <div />
                    <Button
                      type="submit"
                      isLoading={loading}
                      disabled={loading}
                    >
                      Send a message
                    </Button>
                  </div>
                </div>
              </div>
            </form>
            <ToastContainer
              position="bottom-left"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop
              pauseOnFocusLoss
              closeButton={true}
              style={{
                width: width >= 850 ? '30%' : '90%',
                bottom: width >= 850 ? '200px' : '20px',
                left: '20px',
              }}
              toastStyle={{
                borderRadius: '4px',
              }}
            />
          </FormProvider>
        </div>
      </Container>
    </>
  );
};

export default Contact;
